<template>
  <div class="section honeycomb newsletter">
    <div class="container">
      <form @submit="subscribe">
        <input v-model="email" type="email" placeholder="Email..." :disabled="subscribed === 2" class="mail" required>
        <div class="subscription">
          <input v-model="consent" type="checkbox" required>
          <translate> En m’abonnant à la newsletter*, j’autorise la société IELO-LIAZO GROUP ainsi que l’ensemble de ses filiales à me faire parvenir à l’adresse électronique</translate>
          <translate> indiquée ci-dessus l’actualité du Groupe IELO. Je peux révoquer mon consentement à tout moment, en cliquant sur le lien de désinscription présent à la fin</translate>
          <translate> de chaque newsletter</translate>.
        </div>
        <div class="rgpd">
          <translate>Votre adresse de messagerie est uniquement utilisée pour vous envoyer les newsletters. Pour en savoir plus sur la gestion de vos données et vos droits,</translate>
          <translate> consultez notre</translate><a target="_blank" href="static/RGPD.pdf"><translate> Politique de traitement des données personnelles de IELO</translate>. </a>
        </div>
        <div>*Newsletter = lettre d’informations </div>
        <div class="newsletter-submit">
          <button v-if="subscribed === 0" type="submit" class="button button-primary">
            <i class="el-icon-message"></i> <translate>Valider</translate>
          </button>
          <div v-else-if="subscribed === 1"><h4><i class="el-icon-loading"></i></h4></div>
          <div v-else><h5><translate>Envoyé !</translate></h5></div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import settings from '../settings.js'

  export default {
    name: "Newsletter",
    data: function() {
      return {
        subscribed: 0,
        email: "",
        consent: false
      }
    },
    methods: {
      subscribe (ev) {
        ev.preventDefault()
        if (this.email === "" || !this.consent) {
          return false;
        }

        this.subscribed = 1

        axios.post(`${settings.PORTAL_BASE_URL}/public/newsletter/subscribe`, {email: this.email})
          .then(() => {
            this.subscribed = 2
          })
          .catch(() => {
            this.subscribed = 0
          })

        return false
      }
    }
  }
</script>

<style scoped>
  .mail {
    width: 100%;
  }
  .subscription {
    margin-bottom: 0.8rem;
  }
  .rgpd {
    margin-bottom: 0.8rem;
  }
  .newsletter-submit {
    text-align: center;
  }
</style>
