<template>
  <div class="contact-input">
    <form @submit="send">
      <input v-model="foo" type="text" style="display: none;">
      <ul>
        <li>
          <translate>Email</translate>
          <input v-model="email" type="email" maxlength="128" :disabled="sent === 2" required>
        </li>
        <li>
          <translate>Nom (optionnel)</translate>
          <input v-model="name" type="text" maxlength="128" :disabled="sent === 2">
        </li>
        <li>
          <translate>Type de demande</translate>
          <select v-model="routing_type" class="u-full-width" :disabled="sent === 2" required>
            <option value="commercial"><translate>Commercial</translate></option>
            <option value="job"><translate>Recrutement</translate></option>
            <option value="other"><translate>Autre</translate></option>
          </select>
        </li>
        <li>
          <textarea maxlength="4096" v-model="message" placeholder="Message" :disabled="sent === 2" required></textarea>
        </li>
        <li class="contact-consent">
          <label>
            <input v-model="consent" type="checkbox" required>
            <span><translate>J'accepte que ielo me contacte pour traiter ma demande.</translate></span>
          </label>
          <label>
            <input v-model="rgpb" type="checkbox" required>
            <span><translate>Je reconnais avoir pris connaissance de la</translate> <a target="_blank" href="static/RGPD.pdf"> <translate>Politique de traitement des données
              personnelles de IELO. </translate></a></span>
          </label>
        </li>
      </ul>
      <div class="contact-submit">
        <button type="submit" v-if="sent === 0" class="button button-primary">
          <i class="el-icon-message"></i> <translate>Envoyer</translate>
        </button>
        <div v-else-if="sent === 1"><h4><i class="el-icon-loading"></i></h4></div>
        <div v-else><h5><translate>Envoyé !</translate></h5></div>
      </div>
    </form>
  </div>
</template>

<script>
  import axios from 'axios'
  import settings from '../settings.js'

  export default {
    props: [ "default_routing_type" ],
    data: function() {
      return {
        sent: 0,
        name: "",
        email: "",
        message: "",
        consent: false,
        rgpd: false,
        routing_type: this.default_routing_type,
        foo: ""
      }
    },
    methods: {
      send (ev) {
        ev.preventDefault();
        if (this.email === "" || this.message === "" || !this.consent || !this.rgpd) {
          return false;
        }

        const data = {
          email: this.email,
          name: this.name,
          message: this.message,
          routing_type: this.routing_type,
          foo: this.foo
        }

        this.sent = 1

        axios.post(`${settings.PORTAL_BASE_URL}/public/newsletter/message`, data)
          .then(() => {
            this.sent = 2
          })
          .catch(() => {
            this.sent = 0
          })

        return false;
      }
    }
  }
</script>

<style scoped>
  .contact-input ul li {
    list-style: none;
    border-bottom: 1px solid #030D18;
  }

  .contact-input input {
    border: none;
    background: none;
    border-radius: 0;
    width: 100%;
  }

  .contact-consent input {
    width: 5%;
  }

  .contact-input .contact-consent {
    margin-top: 20px;
    border-bottom: none;
  }

  .contact-input textarea {
    border: none;
    background: none;
    border-radius: 0;
    height: 150px;
    width: 100%;
    resize: vertical;
  }

  .contact-input textarea::placeholder {
    color: #8C8B8B;
    font-size: 15px;
  }

  .contact-submit {
    float: right;
  }
</style>
